import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I completed my Biochemistry-Computer Science dual undergraduate degree at the University of Miami. I also
minored in chemistry, French, and mathematics.`}</p>
    <p>{`At UM, I served on the executive board of the Anime Club. Each fall we raised money for Miami Children's Hospital by hosting
`}<a parentName="p" {...{
        "href": "https://www.extra-life.org/"
      }}>{`ExtraLife`}</a>{`, a 24 hour video game marathon.
Each spring we hosted `}<a parentName="p" {...{
        "href": "https://www.themiamihurricane.com/2013/04/14/hurricons-anime-celebration-transforms-campus/"
      }}>{`Miami Hurricon`}</a>{`, a free anime convention that drew thousands of attendees.`}</p>
    <p>{`I also played on the women's ultimate frisbee team in my senior year.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      