import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nature.com/articles/s41467-018-07604-0"
        }}>{`Axolotl limb regeneration`}</a></li>
    </ul>
    <p>{`I conducted research under Dr. Jessica Whited for my Master's
degree. Understanding how axolotl salamanders regenerate their
limbs may lead to insights into pain management and (maybe one
day in the far future) regeneration for human amputees.`}</p>
    <p>{`I used shell scripts and R packages (`}<a parentName="p" {...{
        "href": "https://satijalab.org/seurat/"
      }}>{`Seurat`}</a>{`) to analyze
high throughput sequencing data from regenerating and control
axolotl limbs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      