import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.sciencedirect.com/science/article/pii/S2452014416300310"
        }}>{`Viral DNA elements in the dog genome`}</a></li>
    </ul>
    <p>{`I participated in the `}<a parentName="p" {...{
        "href": "https://molbio.princeton.edu/undergraduate/research/surp"
      }}>{`Princeton Molecular and Quantitative Biology Summer
Undergraduate Research Program`}</a>{`
and conducted research under Dr. Bridgett vonHoldt. Understanding the genome structure of dogs lets us understand
how different breeds have such different traits and disease susceptibility. Viral DNA sequences contribute to genomic
differences.`}</p>
    <p>{`I used shell scripts to identify the coordinates of viral DNA sequences in the dog genome. I then used Python scripts to
investigate the proximity of these elements to coding genes, the time since their insertion, and whether or not they were
silenced differently in dogs versus wolves.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      