import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.ncbi.nlm.nih.gov/pubmed/28319831"
          }}>{`A review on on host-virus protein interactions`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://msystems.asm.org/content/1/2/e00030-15"
          }}>{`How human and bacterial viruses interact with their hosts`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4840434/"
          }}>{`How viruses targeting Streptococcus pneumoniae interact with their hosts`}</a></p>
      </li>
    </ul>
    <p>{`I conducted research under Dr. Stefan Wuchty during my senior undergraduate year. Understanding how viruses target bacterial
hosts may help us develop new therapies for bacteria-borne diseases.`}</p>
    <p>{`I used Python scripts to investigate how viruses interact with their hosts using graph models.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      