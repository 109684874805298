import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I currently work at `}<a parentName="p" {...{
        "href": "https://www.pokemon.com/us/about-pokemon/"
      }}>{`The Pokémon Company International`}</a>{` on the Organized Play team.
I'm helping build a Python3/Django service to replace the monolith that supports `}<a parentName="p" {...{
        "href": "https://www.pokemon.com/us/play-pokemon/"
      }}>{`Play! Pokémon`}</a>{`.
This service currently supplies data to the `}<a parentName="p" {...{
        "href": "https://events.pokemon.com/en-us/"
      }}>{`Event Locator`}</a>{` and `}<a parentName="p" {...{
        "href": "https://events.pokemon.com/en-us/locations"
      }}>{`Retail Locator`}</a>{`.
It also ingests tournament data from the `}<a parentName="p" {...{
        "href": "https://www.pokemon.com/us/pokemon-news/the-new-pokemon-event-manager-has-arrived/"
      }}>{`Pokémon Event Manager`}</a>{`.`}</p>
    <p>{`My current projects involve back-end support for `}<a parentName="p" {...{
        "href": "https://pokemongolive.com/post/pgo-championship-announce-details/?hl=en"
      }}>{`2022 Championships`}</a>{`, including performance improvements and integration with GO/Niantic data.`}</p>
    <p>{`When I'm not writing `}<a parentName="p" {...{
        "href": "https://www.django-rest-framework.org/"
      }}>{`Django REST framework`}</a>{` APIs, designing data flows, or integrating with internal clients,
I periodically assist my front-end teammates with the Pokémon Event Manager, an `}<a parentName="p" {...{
        "href": "https://www.electronjs.org/"
      }}>{`Electron app`}</a>{` that runs tournaments.`}</p>
    <p>{`From 2020 through early 2022, I was a Women in Tech lead and coordinated internal events such as tea-times and Certified Scrum Master training.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      