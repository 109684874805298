import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=cj0iq-bWnsg"
      }}>{`Axolotls`}</a>{`
are salamanders that have the amazing ability to fully
regenerate their limbs upon injury. With recent technological
advances, we can now research how individual cells contribute
to regeneration.`}</p>
    <p>{`In `}<a parentName="p" {...{
        "href": "https://www.whitedlab.com/"
      }}>{`Dr. Jessica Whited's lab`}</a>{`, we used
`}<a parentName="p" {...{
        "href": "https://dx.doi.org/10.1016%2Fj.cell.2015.04.044"
      }}>{`single cell RNA sequencing`}</a>{`
to profile cells from regenerating and intact axolotl limbs. I did
preliminary analysis of the data from our first two
experiments, including transforming the raw gene sequencing data
into high-dimensional gene x cell matrices and identifying
populations of different cell types using linear algebra
techniques.`}</p>
    <p>{`I also participated in `}<a parentName="p" {...{
        "href": "https://bsp.hms.harvard.edu/scholastic-partnerships/hms-kids"
      }}>{`Harvard Medical School Kindling Interest in Doing Science`}</a>{`
summer program and `}<a parentName="p" {...{
        "href": "https://hprep.wordpress.com/"
      }}>{`Health Professions Recruitment & Exposure Program`}</a>{`
to teach middle and high school students about science and to prepare them for pursuing STEM tracks in college.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      